import * as React from 'react';
import Seo from '../components/utils/seo';
import { Layout } from '../components/layout';
import Imprint from '../components/sections/imprint';

// markup
const Impressum = () => {
  return (
    <Layout secondaryMode={true}>
      <Seo title='Impressum'></Seo>
      <Imprint></Imprint>
    </Layout>
  );
};

export default Impressum;
