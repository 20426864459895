import React from 'react';

import './imprint.scss';

const Imprint = () => {
  return (
    <section id='imprint'>
      <div className='section-title'>
        <h2>Impressum</h2>
      </div>
      <div className='container content'>
        <b>Devtory GbR</b> <br></br>
        Mainstraße 80a <br></br>
        16321 Bernau bei Berlin <br></br>
        <br></br>
        USt-IdNr: DE355076143
        <h3>Gesellschafter</h3>
        <div className='person-wrapper'>
          <p>
            Alexander Kirsch <br></br>
            <span className='secondary-text'>Albrechtshofer Weg 29A</span> <br></br>
            <span className='secondary-text'>13591 Berlin</span>
          </p>
          <p>
            Gamzat Mukailov <br></br>
            <span className='secondary-text'>Vincent-Van-Gogh-Str. 34</span> <br></br>
            <span className='secondary-text'>13057 Berlin</span>
          </p>
          <p>
            Julian Naumann <br></br>
            <span className='secondary-text'>Mainstraße 80a</span> <br></br>
            <span className='secondary-text'>16321 Bernau bei Berlin </span>
          </p>
          <p>
            Martin Weber <br></br>
            <span className='secondary-text'>Wesenberger Ring 2</span> <br></br>
            <span className='secondary-text'>12359 Berlin</span>
          </p>
        </div>
        <h3>Kontakt</h3>
        <p>
          E-Mail: <a href='mailto:contact@devtory.io'>contact@devtory.io</a>
        </p>
        <h3>Haftungsausschluss</h3>
        <h4>Haftung für Inhalte</h4>
        <p className='secondary-text'>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
          können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
          nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
          übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
          bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
          Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>
        <h4>Haftung für Links</h4>
        <p className='secondary-text'>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
          diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
          Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
          überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
          verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>
        <p className='note secondary-text'>Angaben gemäß § 5 TMG</p>
      </div>
    </section>
  );
};

export default Imprint;
